@import "../../style/global.scss";

.footer {
  background: var(--delta);
  color: var(--alpha);
  padding: 0 5rem 5rem 5rem;

  @include mobile {
    padding: 3rem;
  }

  &__getStarted {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -10rem;
  }

  .logo {
    img {
      width: 8%;

      @include mobile {
        width: 65%;
      }
    }
  }
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 5rem;

    @include mobile {
      flex-direction: column;
    }
  }

  &__location {
    display: flex;
  }

  &__location-icon {
    margin-right: 3rem;
  }
  &__location-text {
    max-width: 50rem;

    @include mobile {
      margin-bottom: 3rem;
    }
  }

  &__phone {
    img {
      margin-right: 2rem;
    }

    display: flex;
  }

  &__mail {
    img {
      margin-right: 2rem;
    }

    display: flex;
    margin-top: 2rem;
  }
  &__menu {
    display: flex;

    @include mobile {
      flex-direction: column;
      margin-top: 10rem;
      margin-bottom: 10rem;
    }
  }

  &__menu-left {
    margin-right: 4rem;
    @include mobile {
      margin-bottom: 5rem;
    }
  }

  &__menu-item {
    margin-top: 2rem;
    padding: 0 3rem;

    @include mobile {
      padding: 0;
    }
  }

  &__menu-item:first-child {
    margin-top: 0;
  }
  &__menu-item:hover {
    font-weight: bold;
  }
  &__social {
    @include mobile {
      margin: auto;
    }
  }
  &__button {
    border: 2px solid white;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    background: none;
    color: white;
    text-align: center;
    cursor: pointer;
    margin-right: 2rem;
    transition: all 0.5s ease;
  }

  &__button:hover {
    border: 2px solid var(--zeta);
  }
}
