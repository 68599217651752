@import "../../style/global.scss";

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
  margin-top: 7.5rem;
  padding: 2.5rem;

  @include mobile {
    width: 100%;
    margin-top: 5rem;
    padding: 1.5rem;
  }

  &__img {
    margin-bottom: 3rem;
  }
  &__header {
    font-family: var(--chi);
    font-weight: 700;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  &__content {
    width: 45%;
    @include mobile {
      width: 100%;
    }
  }
}
