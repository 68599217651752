:root {
  // Colors
  --alpha: #ffffff;
  --beta: #1c2230; // NavbarBG, HeaderBG , SignUp Card
  --gamma: #181e2a; // IntroBG, SectionBG, TestimonialsBG
  --delta: #0c1524; //Footer
  --epsilon: #21293c; // Testimonial cards,
  --zeta: #65e2d9; // Button gradient, social hover
  --eta: #339ecc; // Button gradient
  --theta: #8cdae4; // Button hover
  --iota: #ff4242; // Error Message

  // Typo
  --phi: "Open Sans", sans-serif;
  --chi: "Raleway", sans-serif;

  //Font Sizes

  --xxlarge: 6rem; //60 px
  --xlarge: 3.6rem; //36 px
  --large: 3rem; //30 px
  --medium: 2.4rem; //24 px
  --small: 2rem; //20 px
  --xsmall: 1.4rem; //14 px
  --xxsmall: 1rem; //10 px
}

//Responsive

$width: 768px;

@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}
