@import "../../style/global.scss";

.getStarted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--beta);
  color: var(--alpha);
  max-width: 90rem;
  max-height: 30rem;
  border-radius: 1rem;
  box-shadow: 5px 5px 2px 1px rgba(0, 0, 0, 0.2);

  @include mobile {
    padding: 2rem;
    max-width: 90rem;
    max-height: 50rem;
  }

  &__header {
    font-family: var(--chi);
    font-weight: bold;
    font-size: 2.5rem;
    margin: 2.5rem 0;

    @include mobile {
      font-size: 2rem;
    }
  }

  &__text {
    margin: 0 3rem;
    @include mobile {
      font-size: 1.5rem;
      margin: 0;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      justify-content: center;
    }
  }
  input {
    padding: 1.5rem 10rem;
    border-radius: 5rem;
    text-align: center;

    @include mobile {
      padding: 1.5rem 3rem;
      margin-top: 2rem;
    }
  }
  &__button {
    margin-bottom: 1.2rem;
    margin-left: 3rem;

    @include mobile {
      margin-left: 0;
    }
  }
}
