@import "../../style/global.scss";

.container {
  display: flex;
  background: var(--gamma);
  color: var(--alpha);
  padding: 10rem 10rem;

  @include mobile {
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
  }
}

.left {
  width: 50%;

  @include mobile {
    width: 100%;
  }

  img {
    @include mobile {
      width: 90%;
    }
  }
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.8rem;
  margin-left: 5rem;

  @include mobile {
    width: 100%;
    margin-left: 0;
    align-items: flex-start;
  }

  &__header {
    font-family: var(--chi);
    font-size: 3.5rem;
    font-weight: 700;
    width: 40%;
    margin-bottom: 2rem;

    @include mobile {
      width: 100%;
      font-size: 2rem;
    }
  }

  &__text {
    width: 75%;
    margin-bottom: 2.5rem;

    @include mobile {
      width: 100%;
      font-size: 1.6rem;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--theta);
    border-bottom: 0.5px solid;
    transition: all 0.3s ease;
  }

  &__link:hover {
    color: var(--alpha);
    #b {
      fill: var(--alpha);
    }
  }

  a {
    margin-right: 1rem;
    margin-bottom: 0.4rem;
  }
}
