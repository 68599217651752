* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  &:after,
  &:before {
    box-sizing: border-box;
  }
}

html {
  /* 1rem = 10px */
  font-size: 62.5%;
  ::-webkit-scrollbar {
    display: none;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 1.6rem; // 16px
  a,
  a:link,
  a:visited {
    text-decoration: none;
    color: inherit;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3 {
    font-size: 1.6rem; // 16px
    font-weight: 400;
  }
}
