@import "../../style/global.scss";

.testimonials-wrapper {
  display: flex;
  justify-content: space-around;
  background: var(--gamma);
  background-image: url(../../assets/bg-quotes.png);
  background-repeat: no-repeat;
  background-attachment: local;
  background-position-x: 12rem;
  background-position-y: 7rem;
  color: var(--alpha);
  padding: 10rem;
  letter-spacing: 0.5px;
  padding-bottom: 25rem;

  @include mobile {
    flex-direction: column;
    align-items: center;
    padding: 15rem 4rem;
    background-position-x: 5rem;
    background-position-y: 14rem;
  }
}
