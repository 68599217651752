@import "../../style/global.scss";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--beta);
  color: var(--alpha);
  text-align: center;

  @include mobile {
    padding: 3rem 4rem;
  }
  img {
    @include mobile {
      width: 90%;
    }
  }
  &__title {
    font-family: var(--chi);
    font-weight: bold;
    font-size: 3rem; // 30px
    width: 30%;
    margin: 4rem 0; // 40px

    @include mobile {
      font-size: 2.2rem;
      width: 100%;
      margin: 2rem 0;
      line-height: 3rem;
    }
  }

  &__text {
    width: 25%;

    @include mobile {
      width: 90%;
    }
  }
}

.btn {
  margin-top: 3rem;
  margin-bottom: 1.4rem;
}
